import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isUserLoggedIn: false,
    username: null,
    notificationCount: 0,
    dark: (localStorage.getItem('JDoodle-Theme') === 'dark'),
    studentUnderEvaluation: null,
    isAppInitiated: false,
    robotChecked: false,
    lastRobotCheckedTime: null
  },
  mutations: {
    initApp (state) {
      state.isAppInitiated = true
    },
    login (state, username) {
      state.isUserLoggedIn = true
      state.username = username
      state.robotChecked = true
      state.lastRobotCheckedTime = Date.now()
    },
    logout (state) {
      state.isUserLoggedIn = false
      state.username = null
      state.robotChecked = false
    },
    notificationCount (state, count) {
      state.notificationCount = count
    },
    toggleTheme (state) {
      state.dark = !state.dark
      if (state.dark) {
        localStorage.setItem('JDoodle-Theme', 'dark')
      } else {
        localStorage.setItem('JDoodle-Theme', 'white')
      }
    },
    studentUnderEvaluation (state, email) {
      state.studentUnderEvaluation = email
    },
    robotCheckDone (state) {
      state.robotChecked = true
      state.lastRobotCheckedTime = Date.now()
    },
    clearRobotCheck (state) {
      state.robotChecked = false
      state.lastRobotCheckedTime = null
    }
  },
  actions: {

  }
})
