import Vue from "vue";
import Router from "vue-router";
import { META } from "./assets/javascript/meta";
import * as _ from "lodash";

const IDE = () => import("./views/IDE");

Vue.use(Router);

let MainChildren = [
  {
    path: "/",
    name: "generalHome",
    component: () => import("./views/Home.vue"),
    meta: META.default,
  },
  {
    path: "/terms/",
    name: "terms",
    component: () => import("./views/Terms.vue"),
    meta: META.default,
  },
  {
    path: "/compiler-api/",
    name: "compilerAPI",
    component: () => import("./views/API.vue"),
    meta: META.api,
  },
  {
    path: "/compiler-ide-plugin/",
    name: "compilerIdePlugin",
    component: () => import("./views/Plugin.vue"),
    meta: META.api,
  },
  {
    path: "/guru/",
    component: () => import("./views/Guru.vue"),
    meta: META.guru,
    children: [
      {
        path: "/",
        name: "guruHome",
        component: () => import("./components/Guru/GuruHome.vue"),
      },
      {
        path: "/guru/ins/:instituteCode/",
        component: () => import("./components/Guru/GuruInstitute.vue"),
        children: [
          {
            path: "/",
            name: "guruInstituteHome",
            component: () => import("./components/Guru/GuruInstituteHome.vue"),
          },
          {
            path: "users",
            name: "guruUsers",
            component: () => import("./components/Guru/GuruUsers.vue"),
          },
          {
            path: "sgs",
            name: "guruSGs",
            component: () => import("./components/Guru/GuruSGs.vue"),
          },
          {
            path: "studentGroup/:insStudentGroupId",
            name: "guruStudentGroup",
            component: () => import("./components/Guru/GuruStudentGroup.vue"),
          },
          {
            path: "assignments",
            name: "guruAssignments",
            component: () =>
              import("./components/Guru/Assignment/GuruAssignments.vue"),
          },
          {
            path: "assignment/:insAssignmentId",
            name: "guruAssignment",
            component: () =>
              import("./components/Guru/Assignment/GuruAssignment.vue"),
          },
          {
            path: "assignment/:insAssignmentId/:mode",
            name: "guruAssignmentAttend",
            component: () =>
              import(
                "./components/Guru/Assignment/GuruAssignmentPreviewAttend.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/education-institutions",
    name: "educationInstitutions",
    component: () => import("./views/EducationInstitutions.vue"),
    meta: META.default,
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("./views/ContactUs.vue"),
    meta: META.default,
  },
  {
    path: "/a/:shareId",
    name: "share",
    component: IDE,
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: false,
    }),
  },
  {
    path: "/ia/:shareId",
    name: "ishare",
    component: IDE,
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: true,
    }),
  },
  {
    path: "/html-css-javascript-online-editor/",
    name: "html",
    component: () => import("./views/HTMLIDE"),
    meta: META.html,
  },
  {
    path: "/h/:shareId",
    name: "htmlShare",
    component: () => import("./views/HTMLIDE"),
    meta: Object.assign({}, META.html, { isShared: true }),
  },
  {
    path: "/register/verifyRegistration",
    name: "verifyRegistration",
    component: () => import("./views/RegistrationVerification"),
    meta: META.default,
  },
  {
    path: "/register/resetPassword",
    name: "resetPassword",
    component: () => import("./views/ResetPassword"),
    meta: META.default,
  },
  {
    path: "/online-mysql-terminal/",
    name: "mysqlTerminal",
    component: () => import("./views/Terminal"),
    meta: META.mysql,
  },
  {
    path: "/online-mongodb-terminal/",
    name: "mongoTerminal",
    component: () => import("./views/Terminal"),
    meta: META.mongo,
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/Logout"),
    meta: META.default,
  },
];

let paths = [];

for (let key in META) {
  let lang = META[key];
  if (lang.isCompile === true || lang.isCompile === false) {
    paths.push("/" + lang.canonicalPath + "/");
    MainChildren.push({
      path: "/" + lang.canonicalPath + "/",
      name: lang.langDisplayName,
      component: IDE,
      meta: lang,
    });

    if (lang.isPlus) {
      let langPlus = _.cloneDeep(lang);
      langPlus.isAdvanced = true;
      langPlus.basicPath = langPlus.canonicalPath;
      langPlus.canonicalPath = langPlus.canonicalPath + "-ide";
      paths.push(langPlus.canonicalPath);
      MainChildren.push({
        path: "/" + langPlus.canonicalPath + "/",
        name: langPlus.langDisplayName + " Advanced IDE",
        component: IDE,
        meta: langPlus,
      });
    }
  }
}

// console.log(paths)

let routes = [
  {
    path: "/",
    component: () => import("./views/GeneralApp"),
    children: MainChildren,
  },
  {
    path: "/langs-and-versions-table/",
    name: "langsAndVersionsTable",
    component: () => import("./views/LangAndVersionsTable"),
  },
  {
    path: "/embed/",
    name: "embed",
    component: () => import("./views/EmbedApp"),
    children: [
      {
        path: "/embed/v0/:shareId",
        name: "embedIde",
        component: () => import("./views/EmbedIDE"),
        meta: { isInstantShare: false, isPlugin: false },
      },
    ],
  },
  {
    path: "/iembed/",
    name: "iembed",
    component: () => import("./views/EmbedApp"),
    children: [
      {
        path: "/iembed/v0/:shareId",
        name: "iembedIde",
        component: () => import("./views/EmbedIDE"),
        meta: { isInstantShare: true, isPlugin: false },
      },
    ],
  },
  {
    path: "/plugin/",
    name: "ideplugin",
    component: () => import("./views/EmbedApp"),
    children: [
      {
        path: "/plugin/",
        name: "simple",
        component: () => import("./views/EmbedIDE"),
        meta: { isInstantShare: false, isPlugin: true },
      },
    ],
  },
  {
    path: "/mobile-app/",
    name: "mobile",
    component: () => import("./views/mobile/MobileApp"),
    children: [
      {
        path: "/mobile-app/login/:token",
        name: "mobileLogin",
        component: () => import("./views/mobile/MobileLogin"),
      },
      {
        path: "/mobile-app/login-success/:token",
        name: "mobileLoginSuccess",
        component: () => import("./views/mobile/MobileLoginSuccess"),
      },
    ],
  },
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});
