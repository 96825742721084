import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash'
import Vuelidate from 'vuelidate'

// JQuery
import JQuery from 'jquery'

// VeeValidate
import VeeValidate from 'vee-validate'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.use(Vuelidate)

// lodash
Object.defineProperty(Vue.prototype, '$_', { value: lodash })

Vue.component('font-awesome-icon', FontAwesomeIcon)

window.$ = JQuery
window.jQuery = JQuery

window.VUUKLE_CONFIG = {
  apiKey: '771ca287-41b6-4c2b-a8d7-a3e1e70ec8ad',
  articleId: '1',
  comments: { enabled: false },
  emotes: { enabled: false },
  powerbar: { enabled: false },
  ads: { noDefaults: true }
}

Vue.use(VeeValidate)

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

require('./assets/javascript/icons')
require('./assets/style/main.scss')

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'development') {
  window.jda = {
    CATEGORY: {
      GLOBAL: 'GLOBAL',
      TERMINAL: 'TERMINAL',
      IDE: 'IDE',
      API: 'API',
      GURU: 'GURU'
    },
    pageView (page) {
      window.ga('send', 'pageview', page)
    },
    pageEvent (category, action, label, value) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      })
    },
    exception (errorThrown) {
      window.ga('send', 'exception', {
        exDescription: errorThrown,
        exFatal: false
      })
    }
  }
} else {
  window.jda = {
    CATEGORY: {},
    pageView (page) {},
    pageEvent (category, action, label, value) {},
    exception (errorThrown) {}
  }
}

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

router.afterEach((to, from) => {
  // Page View
  window.jda.pageView(to.path)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
